/* eslint-disable react/no-danger */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-else-return */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, {useState, useContext, useEffect} from 'react'
import Form from 'semantic-ui-react/dist/commonjs/collections/Form'
import Button from 'semantic-ui-react/dist/commonjs/elements/Button'
import Dropdown from 'semantic-ui-react/dist/commonjs/modules/Dropdown'
import Transition from 'semantic-ui-react/dist/commonjs/modules/Transition'
import Checkbox from 'semantic-ui-react/dist/commonjs/modules/Checkbox'

import {Link} from 'gatsby'
import styled from 'styled-components'
import {formatMoney, scrollToTop} from '../../utils'
import CartContext from '../Context/CartContext'
import IconPlus from '../../images/icons/icon-plus-circle.svg'
import QuantityDropdown from '../QuantityDropdown'
import {analyticsAddToCart} from '../../services/GoogleAnalytics'
import {pixelAddToCart} from '../../services/FacebookPixel'

const AddToCartWrapper = styled.div`
  .blur {
    filter: blur(3px);
  }
  .price {
    color: ${props => props.theme.grey2};
    font-size: 30px;
    margin: 0 0 30px 0;
    line-height: 30px;
    display: block;
  }
  .mattress-select,
  .base-select {
    background: #e7e7e7;
    border-radius: 2px;
    padding: 10px;
    margin-bottom: 10px;
    .row {
      display: grid;
      grid-template-columns: 1fr 150px;
      align-items: center;
      grid-gap: 10px;
      .select-label {
        text-transform: uppercase;
        color: rgba(81, 81, 96, 0.6);
        padding: 5px 5px 8px 5px;
        display: block;
      }
    }
  }
  .base-select {
    & > label {
      color: rgba(81, 81, 96, 0.6) !important;
      padding-left: 25px;
    }
    .base-checkbox {
      margin: 5px 0 10px 2px;
    }
  }
  .qty {
    margin: 0;
  }
  .add-button {
    background: ${props => props.theme.red};
    text-transform: uppercase;
    color: rgba(255, 255, 255, 0.9);
    border-radius: 3px;
    padding: 20px 55px 20px 30px;
    position: relative;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    &:after {
      content: url(${props => props.IconPlus});
      //position: absolute;
      //top: 15px;
      //right: 20px;
      display: inline-block;
      margin-left: 15px;
    }
    &:hover {
      color: #fff;
      background: ${props => props.theme.redHover} !important;
    }
  }
  .message {
    margin-top: 20px !important;
  }
  @media (max-width: 1024px) and (min-width: 768px) {
    .mattress-select,
    .base-select {
      .row {
        grid-template-columns: 1fr;
      }
    }
  }
  @media (max-width: 600px) {
    .mattress-select,
    .base-select {
      .row {
        grid-template-columns: 1fr;
      }
    }
    .add-button {
      width: 100%;
    }
  }
`
const BadgeContainer = styled.div`
  border: none;
  margin: 0;
  display: inline;
`
const Badge = styled.div`
  font-weight: bold;
  padding: 2px 8px;
  display: inline-block;
  margin: 5px 5px 5px 0;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 12px;
  float: right;
`

const Delivery = styled.div`
  margin-bottom: 10px;
  color: gray;
  font-size: 15px;'
`
const AddToCart = ({
  product,
  stock,
  baseProducts,
  sleepdotProduct,
  selectedVariation,
  selectedBase,
  setSelectedVariation,
  includeBase,
  includeBaseCallback,
  selectedBaseCallback,
  mainProductStockSet,
}) => {
  const [loading, setLoading] = useState(false)
  const [mainProdQuantity, setMainProdQuantity] = useState(1)
  const [baseQuantity, setBaseQuantity] = useState(1)
  const [productDropdown, setProductDropdown] = useState([])
  const [basesDropdown, setBasesDropdown] = useState([])
  const [visible, setVisible] = useState(false)
  const [apiError, setApiError] = useState([])
  const [cartAdded, setCartAdded] = useState(false)
  const [selectedBaseVariation, setSelectedBaseVariation] = useState(
    selectedBase && selectedBase.product_variations[0],
  )
  const [errorInfo, setErrorInfo] = useState([])
  const [noStock, setNoStock] = useState(false)
  const [cartTotal, setCartTotal] = useState('0')
  const [preCart, setPreCart] = useState([])
  const {addToCart} = useContext(CartContext)

  const [onLoadSelectedSku, setOnLoadSelectedSku] = useState()

  useEffect(() => {
    let selectedVariStock = stock[selectedVariation.id]
    for (
      let drop_i = 0;
      selectedVariStock === 0 && drop_i < productDropdown.length;
      ++drop_i
    ) {
      selectedVariStock = stock[productDropdown[drop_i].variant.id]
      if (selectedVariStock) {
        setSelectedVariation(productDropdown[drop_i].variant)
      }
    }
    if (
      selectedVariation &&
      typeof selectedVariation.product_variations !== 'undefined' &&
      selectedVariation.product_variations.length > 0
    ) {
      selectedVariation.product_variations.forEach(vari => {
        if (vari.price === selectedVariation.price) {
          setOnLoadSelectedSku(vari.sku)
        }
      })
    }
  }, [selectedVariation, stock, productDropdown])

  useEffect(() => {
    // update product stock status based on newly fetched stock levels
    let prod_stock_status = 'outofstock'
    product.product_variations.forEach(vari => {
      if (stock.hasOwnProperty(vari.id)) {
        if (stock[vari.id] > 0) {
          prod_stock_status = 'instock'
        }
      }
    })
    product.stock_status = prod_stock_status

    // update all bases stock status based on newly fetched stock levels
    for (let base_i = 0; base_i < baseProducts.length; ++base_i) {
      const base = baseProducts[base_i]
      let base_stock_status = 'outofstock'
      base.product_variations.forEach(vari => {
        if (stock.hasOwnProperty(vari.id)) {
          if (stock[vari.id] > 0) {
            base_stock_status = 'instock'
          }
        }
      })
      baseProducts[base_i].stock_status = base_stock_status
    }

    // When page renders or stock changes, update sizes dropdown
    if (product.product_variations.length !== 0) {
      // Build dropdown options for sizes
      const variationOptions = product.product_variations
        .map(variant => {
          const dropdownItem = {
            key: variant.id,
            text: variant.attributes[0].option,
            rawprice: variant.price,
            description: formatMoney(variant.price),
            value: variant.sku,
            variant,
          }

          // Out of stock? Show label
          if (stock[variant.id] < 1) {
            // On NZ site, hide the size completely when out of stock
            if (process.env.GATSBY_WC_COUNTRY !== 'za') {
              return null
            }
            dropdownItem.label = {content: 'OUT OF STOCK', color: 'red'}
          }
          return dropdownItem
        })
        .filter(i => !!i)

      // Custom Dropdown size order of appearance (cosmetic only)
      const sortingArr = [
        'Single',
        'Single XL',
        'Single and XL',
        'Three Quarter',
        'Three Quarter XL',
        'Three Quarter and XL',
        'Double',
        'Double XL',
        'Double and XL',
        'Queen',
        'Queen XL',
        'Queen and XL',
        'King',
        'King XL',
        'King and XL',
      ]
      const variationOptionsSorted = variationOptions.sort(
        (a, b) => sortingArr.indexOf(a.text) - sortingArr.indexOf(b.text),
      )
      setProductDropdown(variationOptionsSorted)
    }
  }, [stock, product])

  // When selected mattress size changes, build new dropdown for bases
  useEffect(() => {
    if (product.categories.some(it => it.name === 'Mattresses')) {
      let variationSize = selectedVariation.sku.split('-')
      variationSize =
        variationSize.length > 0 && variationSize[variationSize.length - 1]
      // Build dropdown options for bases
      const baseOptions =
        variationSize &&
        baseProducts
          .map(base => {
            // Find the variant (size) of the base that matches our mattress
            const sizeMatched = base.product_variations.filter(variant => {
              const sizeCheck = variant.sku.split('-')
              return (
                sizeCheck.length > 0 &&
                sizeCheck[sizeCheck.length - 1] === variationSize
              )
            })[0]
            if (sizeMatched) {
              const dropdownItem = {
                key: sizeMatched.id,
                text: `${base.name}`,
                rawprice: sizeMatched.price,
                description: formatMoney(sizeMatched.price),
                value: sizeMatched.sku,
                product: sizeMatched,
              }

              // Out of stock? Show label
              if (stock[sizeMatched.id] < 1) {
                dropdownItem.label = {content: 'OUT OF STOCK', color: 'red'}
              }

              // Try to match existing selection
              if (selectedBase.sku === base.sku) {
                setSelectedBaseVariation(sizeMatched)
              }
              return dropdownItem
            }
          })
          .filter(notUndefined => notUndefined)
      if (baseOptions.length < 1) {
        includeBaseCallback(false)
      }
      // Sort Dropdown options by price
      const baseOptionsSorted = baseOptions.sort((a, b) =>
        a.rawprice > b.rawprice ? 1 : -1,
      )
      setBasesDropdown(baseOptionsSorted)
    }
  }, [stock, selectedVariation])

  // THIS HAS BEEN REMOVED because it would never reliably pick the first option in the dropdown
  // // When stock loads, change the selection (just for defaults)
  // useEffect(() => {
  //   // Try to change selected size if this one is out of stock
  //   if (mainProductStockSet && !stock[selectedVariation.id]) {
  //     for (let i = 0; i < productDropdown.length; i += 1) {
  //       if (stock[productDropdown[i].key]) {
  //         const newVar = product.product_variations.find(
  //           it => it.id === productDropdown[i].key,
  //         )
  //         if (newVar) setSelectedVariation(newVar)
  //         break
  //       }
  //     }
  //   }
  // }, [productDropdown, stock])

  // When any selection or quantity changes, evaluate the total price and
  // whether selections and quantities are valid
  useEffect(() => {
    // Cap quantities to stock levels, but don't make them zero (because it looks weird)
    if (
      stock[selectedVariation.id] > 0 &&
      mainProdQuantity > stock[selectedVariation.id]
    ) {
      setMainProdQuantity(stock[selectedVariation.id])
      return
    } else if (stock[selectedVariation.id] < 1) {
      setMainProdQuantity(1)
    }

    if (!selectedBaseVariation) {
      setBaseQuantity(1)
    } else if (
      stock[selectedBaseVariation.id] > 0 &&
      baseQuantity > stock[selectedBaseVariation.id]
    ) {
      setBaseQuantity(stock[selectedBaseVariation.id])
      return
    } else if (stock[selectedBaseVariation.id] < 1) {
      setBaseQuantity(1)
    }

    const totalCart = []
    const error = {}

    totalCart.push({
      product:
        selectedVariation.attributes.length > 0 &&
        selectedVariation.attributes[0].option !== undefined
          ? product.name + ' ' + selectedVariation.attributes[0].option
          : product.name,
      item: selectedVariation,
      categories: product.categories,
      quantity: mainProdQuantity,
    })
    if (
      stock[selectedVariation.id] < mainProdQuantity ||
      stock[selectedVariation.wordpress_id] < mainProdQuantity
    ) {
      let errorMsg = `${product.name}`
      if (
        product.stock_status !== 'outofstock' &&
        selectedVariation.attributes[0]
      ) {
        errorMsg += ` ${selectedVariation.attributes[0].option || ''}`
      }
      if (
        stock[selectedVariation.id] < 1 ||
        stock[selectedVariation.wordpress_id] < 1
      ) {
        errorMsg += ' out of stock'
      } else {
        errorMsg += ` only ${stock[selectedVariation.id] ||
          stock[selectedVariation.wordpress_id]} in stock`
      }
      error[selectedVariation.sku] = errorMsg
    }

    if (product.categories.some(it => it.name === 'Mattresses')) {
      // Accessory (base) if checkbox ticked
      if (includeBase) {
        totalCart.push({
          product:
            selectedBase.name +
            ' ' +
            selectedBaseVariation.attributes[0].option,
          item: selectedBaseVariation,
          categories: selectedBase.categories,
          quantity: baseQuantity,
        })
        if (stock[selectedBaseVariation.id] < baseQuantity) {
          let errorMsg = `${selectedBase.name}`
          if (selectedBase.stock_status !== 'outofstock') {
            errorMsg += ` ${selectedBaseVariation.attributes[0].option}`
          }
          if (stock[selectedBaseVariation.id] < 1) {
            errorMsg += ' out of stock'
          } else {
            errorMsg += ` only ${stock[selectedBaseVariation.id]} in stock`
          }
          error[selectedBaseVariation.sku] = errorMsg
        }
      }
      // Accessory (promo addon) if it was defined
      if (sleepdotProduct)
        totalCart.push({
          item: sleepdotProduct,
          categories: sleepdotProduct.categories,
          quantity: mainProdQuantity,
        })
    }

    // Calculate total cost of products in our array and check if they are in stock
    let totalPriceCents = 0
    totalCart.forEach(({item, quantity}) => {
      totalPriceCents += item.price * quantity
    })
    setPreCart(totalCart)
    setNoStock(totalCart.length < 1)
    setCartTotal(totalPriceCents)
    setErrorInfo(error)
  }, [
    stock,
    selectedVariation,
    selectedBaseVariation,
    mainProdQuantity,
    baseQuantity,
    includeBase,
  ])

  // Timeout for "Added to Cart" message
  // const toggleMessage = () => {
  //   setTimeout(() => {
  //     setVisible(false)
  //   }, 2000)
  // }

  // Handle "Add to Cart" button
  const handleSubmit = async () => {
    setLoading(true)
    let newErrors = []

    let i = 0
    for (i = 0; i < preCart.length; i += 1) {
      try {
        const responseData = await addToCart(
          preCart[i].item,
          preCart[i].quantity,
        )
        if (i === preCart.length - 1) {
          setVisible(true)
          // toggleMessage()
        }
      } catch (responseErr) {
        console.log('Add to cart EXCEPTION')
        setVisible(false)
        newErrors = [
          ...newErrors,
          {
            heading: preCart[i].item.sku,
            message: responseErr.message || 'Something went wrong',
          },
        ]
      }
    }
    setCartAdded(true)
    setLoading(false)
    setApiError(newErrors)

    if (newErrors.length === 0) {
      const itemsStruct = preCart.map(item => {
        const retItem = {
          id: item.item.sku,
          name: item.product || item.item.name,
          quantity: item.quantity,
          price: item.item.price,
          categories: item.categories,
        }
        return retItem
      })
      analyticsAddToCart(itemsStruct)
      pixelAddToCart(itemsStruct, cartTotal)
    }
  }

  // Handling functions for checkbox and dropdowns
  // Variation (mattress size) change
  const handleVariationChange = (event, data) => {
    setSelectedVariation(
      product.product_variations.filter(pr => pr.sku === data.value)[0],
    )
    setOnLoadSelectedSku(
      product.product_variations.filter(pr => pr.sku === data.value)[0],
    )
    setApiError([])
  }
  // Accessory (base type) change
  const handleAccessoryChange = (event, data) => {
    // variant sku to parent sku: 004-BAS-KING => 004-BAS
    const strippedSku = data.value.substring(0, data.value.lastIndexOf('-'))
    const newSelectedBase = baseProducts.filter(pr => pr.sku === strippedSku)[0]
    selectedBaseCallback(newSelectedBase)

    const newselectedBaseVariation = newSelectedBase.product_variations.filter(
      pr => pr.sku === data.value,
    )[0]

    setSelectedBaseVariation(newselectedBaseVariation)
    setApiError([])
  }
  // Include base checkbox change
  const handleAccessoryCheckbox = (event, data) => {
    // When the base checkbox is changed, scroll to top (specifically an issue on mobile)
    scrollToTop()
    includeBaseCallback(data.checked)
    setApiError([])
  }

  // Logic for when to show the Out Of Stock badge
  const selectedProductNoStock =
    stock[selectedVariation.id] < 1 || stock[selectedVariation.wordpress_id] < 1
  const selectedBaseNoStock =
    includeBase && selectedBaseVariation && stock[selectedBaseVariation.id] < 1
  const outOfStock =
    selectedProductNoStock ||
    selectedBaseNoStock ||
    (product.product_variations &&
      product.product_variations.length > 0 &&
      productDropdown.length < 1)

  return (
    <AddToCartWrapper IconPlus={IconPlus}>
      <div className={`price${mainProductStockSet ? '' : ' blur'}`}>
        {cartTotal
          ? formatMoney(cartTotal)
          : formatMoney(selectedVariation.price)}
        <BadgeContainer>
          <Badge
            style={{
              backgroundColor: outOfStock ? `#db2828` : `#21ba45`,
              color: 'hsla(0,0%,100%,.9)',
            }}
          >
            {outOfStock ? 'OUT OF STOCK' : 'IN STOCK'}
          </Badge>
        </BadgeContainer>
      </div>
      {noStock ? <div className="message error">OUT OF STOCK</div> : null}
      <Form onSubmit={handleSubmit}>
        {product.product_variations.length > 1 && (
          <>
            <div className="mattress-select">
              <div className="row">
                <div>
                  <span className="select-label">Mattress Size</span>
                  <Dropdown
                    disabled={
                      !mainProductStockSet || productDropdown.length < 1
                    }
                    fluid
                    selection
                    scrolling
                    value={onLoadSelectedSku}
                    options={productDropdown}
                    onChange={handleVariationChange}
                    text={selectedVariation.attributes[0].option}
                  />
                </div>
                <div>
                  <span className="select-label">Quantity</span>
                  <QuantityDropdown
                    disabled={
                      !mainProductStockSet || productDropdown.length < 1
                    }
                    quantity={mainProdQuantity}
                    setQuantity={setMainProdQuantity}
                    stockLevel={
                      (stock[selectedVariation.id] < 1
                        ? 0
                        : stock[selectedVariation.id]) || 1
                    }
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {product.categories.some(it => it.name === 'Mattresses') &&
          selectedBase && (
            <>
              <div className="base-select">
                <div className="row">
                  <div>
                    <Checkbox
                      checked={includeBase}
                      label={
                        basesDropdown.length > 0
                          ? 'Include base'
                          : 'No base for this size'
                      }
                      onChange={handleAccessoryCheckbox}
                      className="base-checkbox"
                      disabled={
                        product.stock_status == 'outofstock' ||
                        basesDropdown.length < 1
                      }
                    />
                    <Dropdown
                      fluid
                      selection
                      disabled={!includeBase}
                      value={selectedBaseVariation.sku || ''}
                      options={basesDropdown}
                      onChange={handleAccessoryChange}
                      text={selectedBase.name}
                    />
                  </div>
                  {selectedBaseVariation && (
                    <div>
                      <span className="select-label">Quantity</span>
                      <QuantityDropdown
                        quantity={baseQuantity}
                        setQuantity={setBaseQuantity}
                        stockLevel={
                          (stock[selectedBaseVariation.id] < 1
                            ? 0
                            : stock[selectedBaseVariation.id]) || 1
                        }
                        disabled={!includeBase}
                      />
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        <Delivery>
          Delivery Estimate: 3 to 5 business days (main city hubs)
        </Delivery>
        <Button
          loading={loading}
          disabled={
            loading ||
            isNaN(mainProdQuantity) ||
            Object.values(errorInfo).length !== 0
            // || !mainProductStockSet
          }
          type="submit"
          className="add-button"
        >
          Add to Cart
        </Button>
      </Form>
      <Transition
        duration={{hide: 0, show: 500}}
        animation="fade down"
        visible={visible}
      >
        <div className="message success">
          {mainProdQuantity} x {product.name} added to cart
          <Link to="/cart/">Proceed to checkout</Link>
        </div>
      </Transition>
      {Object.values(errorInfo).map(err =>
        err ? (
          <div className="message error" key={err}>
            {err}
          </div>
        ) : null,
      )}
      {apiError.length > 0 &&
        apiError.map(err => (
          <div className="message error" key={err.heading}>
            {err.heading && <strong>{err.heading}</strong>}
            <p
              dangerouslySetInnerHTML={{
                __html: err.message,
              }}
            />
          </div>
        ))}
    </AddToCartWrapper>
  )
}

export default AddToCart
