import YouTube from 'react-youtube'
import React from 'react'
import styled from 'styled-components'

const videoLink = ({videoLink}) => {
  let videoId = videoLink && videoLink.split('v=')
  if (videoId && videoId.length) {
    videoId = videoId[videoId.length - 1]
  } else {
    videoId = undefined
  }

  return (
    <YoutubeWrapper>
      <YouTube
        videoId={videoId} // defaults -> null
        // id={string}                       // defaults -> null
        // className={string}                // defaults -> null
        // containerClassName={string}       // defaults -> ''
        // opts={obj}                        // defaults -> {}
        // onReady={func}                    // defaults -> noop
        // onPlay={func}                     // defaults -> noop
        // onPause={func}                    // defaults -> noop
        // onEnd={func}                      // defaults -> noop
        // onError={func}                    // defaults -> noop
        // onStateChange={func}              // defaults -> noop
        // onPlaybackRateChange={func}       // defaults -> noop
        // onPlaybackQualityChange={func}    // defaults -> noop
      />
    </YoutubeWrapper>
  )
}
export default videoLink

const YoutubeWrapper = styled.div`
  div {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 700px;
    @media (max-width: 1024px) {
      height: calc((100vw - 60px) * 0.652);
    }
    iframe {
      width: 100%;
      height: 700px;
      @media (max-width: 1024px) {
        height: calc((100vw - 60px) * 0.652);
      }
    }
  }
`
