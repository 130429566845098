import React, {useState, useContext, useEffect} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import {slugify} from '../../utils'
import styled from 'styled-components'
import VideoLink from './VideoLink'

const ProductVideo = ({productName}) => {
  const data = useStaticQuery(graphql`
    {
      product_data: wordpressWpMattress(
        slug: {eq: "kooi-premium-series-comparison"}
      ) {
        slug
        title
        acf {
          add_youtube_videos {
            youtube_video_url
            exact_product_title
          }
        }
      }
    }
  `)
  const productVideos = data.product_data.acf.add_youtube_videos

  // Check if there is a product video for this product
  const productVideo = productVideos.find(
    pr => slugify(productName) == slugify(pr.exact_product_title),
  )

  if (productVideo) {
    return (
      <YoutubeVideoContainer>
        <Inner>
          <VideoLink videoLink={productVideo.youtube_video_url} />
        </Inner>
      </YoutubeVideoContainer>
    )
  } else {
    return <></>
  }
}

export default ProductVideo

const YoutubeVideoContainer = styled.div`
  width: 100%;
  background-color: #162232;
`

const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 40px 40px;
  align-items: flex-start;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 768px) {
    padding: 30px 30px;
    grid-gap: 20px;
  }
`
