/* eslint-disable camelcase */
import React from 'react'
import Tab from 'semantic-ui-react/dist/commonjs/modules/Tab'

import {useStaticQuery, graphql, Link} from 'gatsby'
import styled from 'styled-components'
// import ProductFeatures from './ProductFeatures'
import IconsRow from '../Icons/IconsRow'
import Reviews from './Reviews'
import ProductVideo from './ProductVideo'
import {slugify} from '../../utils'
import useMobileHook from '../../utils/use-mobile-hook'

const ProductAttributesWrapper = styled.div`
  background: ${props => props.theme.grey2};
  color: #d4d4d5;
  .ui.attached.menu,
  .ui.attached.segment {
    margin: 0 !important;
    width: 100% !important;
    max-width: 100% !important;
  }
`

const PaneWrapper = styled.div`
  h1,
  h3,
  h4,
  h5 {
    text-align: left;
    color: #d4d4d5;
  }
  p {
    color: #d4d4d5 !important;
  }
  h2 {
    text-align: left;
    color: #d4d4d5;
    border-left: 5px solid ${props => props.h2Color};
    background-color: rgba(0, 0, 0, 0.1);
    padding: 15px;
    font-size: 23px;
  }
  h3 {
    color: #d4d4d5;
    font-weight: 400;
    font-size: 20px;
    border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  }
`

const Inner = styled.div`
  max-width: ${props => props.theme.maxWidth};
  margin: 0 auto;
  padding: 40px 40px;
  align-items: flex-start;
  .ui.pointing.top.menu {
    border: none;
    border-bottom: 2px solid ${props => props.theme.grey3};
    a {
      color: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      font-size: 20px;
      font-weight: 400 !important;
      &.active {
        color: rgba(255, 255, 255, 1) !important;
        border-bottom: 2px solid ${props => props.theme.red};
        &:hover {
          color: rgba(255, 255, 255, 1) !important;
          border-bottom: 2px solid ${props => props.theme.redHover};
        }
      }
      &:hover {
        //color: rgba(255, 255, 255, 1);
        border-bottom: 2px solid ${props => props.theme.white};
        color: #FF043A !important;
      }
    }
  }
  .ui.bottom.attached.tab {
    background: ${props => props.theme.grey2};
    border: none;
    border-radius: 0;
    p {
      color: white;
    }
  }
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    // .ui.bottom.attached.tab {
    //   background-color: rgba(255, 255, 255, 0.05);
    // }
  }
  @media (max-width: 768px) {
    padding: 30px 30px;
    grid-gap: 20px;
    .ui.pointing.top.menu {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      justify-items: center;
      a {
        width: 100%;
        text-align: center;
        justify-self: center;
        display: block;
        width: 100%;
        padding: 25px 10px;
      }
    }
    .ui.pointing.top.menu {
      a {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .ui.bottom.attached.tab {
      font-size: 95%;
    }
  }
  @media (max-width: 330px) {
    .ui.pointing.top.menu a {
        padding: 20px 5px;
      }
    }   
  }
`

export default ({
  description,
  specs,
  productName,
  reviews,
  rating,
  productId,
}) => {
  const isMobile = useMobileHook({maxWidth: 900})

  // const data = useStaticQuery(graphql`
  //   {
  //     product_data: wordpressWpMattress(
  //       slug: {eq: "kooi-premium-series-comparison"}
  //     ) {
  //       slug
  //       title
  //       acf {
  //         add_youtube_videos {
  //           youtube_video_url
  //           exact_product_title
  //         }
  //       }
  //     }
  //   }
  // `)
  // const productVideos = data.product_data.acf.add_youtube_videos

  // const descriptionParagraphs = descriptionText.split(/(?:\r\n|\r|\n)/g)
  // const specsParagraphs = specsText.split(/(?:\r\n|\r|\n)/g)
  let h2Color = 'white'
  h2Color = productName.includes('Pure Firm') ? '#ff003a' : h2Color
  h2Color = productName.includes('Pure Medium') ? '#f2d400' : h2Color
  h2Color = productName.includes('Pure Plush') ? '#5fc4e8' : h2Color

  // Build the tabs if there is content for them
  const panes = []

  if (description && description.length) {
    panes.push({
      menuItem: isMobile ? 'Description' : 'Product Description',
      render: () => (
        <PaneWrapper h2Color={h2Color}>
          <Tab.Pane dangerouslySetInnerHTML={{__html: description}} />
        </PaneWrapper>
      ),
    })
  }

  // Check if there is a product video for this product
  // const productVideo = productVideos.find(
  //   pr => slugify(productName) == slugify(pr.exact_product_title),
  // )
  // if (productVideo) {
  //   panes.push({
  //     menuItem: 'Videos',
  //     render: () => (
  //       <PaneWrapper h2Color={h2Color}>
  //         <Tab.Pane />
  //         <ProductVideo
  //           videoLink={productVideo && productVideo.youtube_video_url}
  //         />
  //       </PaneWrapper>
  //     ),
  //   })
  // }

  // if (productName.includes('Kooi') || (specs && specs.length)) {
  //   panes.push({
  //     menuItem: isMobile ? 'Specs' : 'Specifications',
  //     render: () => (
  //       <PaneWrapper h2Color={h2Color}>
  //         <Tab.Pane dangerouslySetInnerHTML={{__html: specs}} />
  //         <ProductFeatures product={productName} />
  //       </PaneWrapper>
  //     ),
  //   })
  // }

  // Add "Reviews" tab
  panes.push({
    menuItem: 'Reviews',
    render: () => (
      <PaneWrapper h2Color={h2Color}>
        <Tab.Pane />
        <Reviews reviews={reviews} rating={rating} productId={productId} />
      </PaneWrapper>
    ),
  })

  return (
    <ProductAttributesWrapper>
      <IconsRow />
      <Inner>
        <div>
          <Tab
            menu={{secondary: true, pointing: true, attached: 'top'}}
            panes={panes}
          />
        </div>
      </Inner>
    </ProductAttributesWrapper>
  )
}
